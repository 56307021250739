import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, useTheme, Grid, Button, useMediaQuery } from '@mui/material';


const PREFIX = 'VideoSectionTitle';

const classes = {
  h2: `${PREFIX}-h2`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`,
  p: `${PREFIX}-p`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.h2}`]: {
    color: '#ffffff !important',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      paddingBottom: 0,
      marginBottom: '0 !important',
    }
  },

  [`& .${classes.buttonContainer}`]: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },

  [`& .${classes.button}`]: {
    marginBottom: theme.spacing(1 / 2),
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    textTransform: 'lowercase !important',
    backgroundColor: '#ffffff !important',
    color: `${theme.palette.primary.main} !important`,
    minWidth: 218
  },

  [`& .${classes.p}`]: {
    color: `${theme.palette.common.white} !important`,
    marginBottom: `${theme.spacing(6)} !important`,
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontSize: 20,
    lineHeight: '23px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  }
}));

const VideoSectionTitle = () => {

  const theme = useTheme()
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"))

  return (
    (<Root>
      <Grid container wrap="nowrap" alignItems="flex-end" justifyContent="space-between">
        <Grid item>
          <Typography component="h2" variant={ matchesDesktop ? "h2" : "h1"} className={classes.h2} gutterBottom>
            {
              matchesDesktop ?
              "Grain conveyor on video" :
              "Watch how simple our conveyors are"
            }
          </Typography>
        </Grid>
          <Grid item className={classes.buttonContainer}>
            <Button
            component="a"
            href="https://www.youtube.com/channel/UCM390aYFA8PZ2pcKNtXwLfQ/videos"
            target="_blank"
            rel="noopener norefferrer"
            variant="contained"
            className={classes.button}>
              more video
            </Button>
          </Grid>
      </Grid>
      <Typography component="p" variant="body1" gutterBottom className={classes.p}>
        Watch how simple to use our conveyors  
      </Typography>
    </Root>)
  );
};

export default VideoSectionTitle;