import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import clsx from 'clsx';

const PREFIX = 'VideoPicker';

const classes = {
  slide: `${PREFIX}-slide`,
  input: `${PREFIX}-input`,
  img: `${PREFIX}-img`,
  activeImg: `${PREFIX}-activeImg`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.slide}`]: {
    height: 'auto !important',
  },

  [`& .${classes.input}`]: {
    display: 'none',
    '&:focus img': {
      outline: '4px solid #ffffff80'
    }
  },

  [`& .${classes.img}`]: {
    display: 'block',
    width: '100%',
    height: "auto",
    boxSizing: 'border-box',
    border: '4px solid transparent',
    transition: 'all 0.3s',
    cursor: 'pointer'
  },

  [`& .${classes.activeImg}`]: {
    border: '4px solid white',
    boxShadow: 'inset 6px 6px 10px #ffffff'
  }
}));

export default function({ videos, currentVideo, setCurrentVideo, direction }) {


  if(!videos) {
    return 'no products to show'
  }

  return (
    <StyledGrid container spacing={2} direction={ direction === 'horizontal' ? "row" : "column"}>
      {
        videos
          .sort((slideA, slideB) => slideA.id - slideB.id)
          .map((slide, i) => {
          return (
            <Grid 
              key={slide.id} 
              item 
              className={classes.slide} 
              xs={direction === 'horizontal' ? 3 : 12} 
              style={{ height: '100%'}}
            >
              <input id={slide.id} type="radio" checked={slide.id === currentVideo.id} onChange={() => setCurrentVideo(slide)} className={classes.input} />
                <label htmlFor={slide.id}>
                  <img
                  width={480}
                  height={360}
                  src={slide.thumb}
                  alt={`video ${i}`}
                  className={clsx(classes.img, slide.id === currentVideo.id && classes.activeImg)}
                  />
                </label>
            </Grid>
          )
        })
      }
    </StyledGrid>
  );
}