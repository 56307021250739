import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { Button, Container, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useStaticQuery, graphql } from 'gatsby'
import VideoPicker from './VideoPicker'
import VideoSectionTitle from './VideoSectionTitle'
import LiteYouTubeEmbed from "react-lite-youtube-embed"
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import './lite-youtube-embed-customized.css'

const PREFIX = 'VideoSection';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  innerWrapper: `${PREFIX}-innerWrapper`,
  videoGridContainer: `${PREFIX}-videoGridContainer`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  button: `${PREFIX}-button`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.wrapper}`]: {
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.up("md")]: {
      backgroundColor: "rgba(231, 182, 7, 0.65)",
    }
  },

  [`& .${classes.innerWrapper}`]: {
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(10)
    }
  },

  [`& .${classes.videoGridContainer}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.buttonWrapper}`]: {
    textAlign: 'right',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },

  [`& .${classes.button}`]: {
    marginBottom: theme.spacing(2),
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    textTransform: 'lowercase !important',
    backgroundColor: '#ffffff !important',
    color: `${theme.palette.secondary.main} !important`,
    minWidth: 218
  }
}));

function VideoSection(props) {

  const theme = useTheme()
  const matchesDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { allWpVideo: { rawVideos }} = useStaticQuery(graphql`
  query Videos {
    allWpVideo {
      rawVideos: nodes {
        videoDetails {
          youtubeid
        }
        id
      }
    }
  }
`)

const videos = rawVideos.map(video => {
  return {
    id: video.videoDetails?.youtubeid,
    src: `https://www.youtube.com/embed/${video.videoDetails.youtubeid}`,
    thumb: `https://img.youtube.com/vi/${video.videoDetails.youtubeid}/mqdefault.jpg`
  }
})
  const [ currentVideo, setCurrentVideo ] = useState(videos[0] || {})

  if(!videos.length) return null;

  return (
    <Root className={classes.wrapper}>
      <Container maxWidth="md" disableGutters>
        <div className={classes.innerWrapper}>
          <VideoSectionTitle />
            <Grid container spacing={2} className={classes.videoGridContainer}>
              <Grid item xs={12} md={9}>
                <LiteYouTubeEmbed
                  id={currentVideo.id} // Default none, id of the video or playlist
                  adNetwork={false} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
                  playlist={false} // Use  true when your ID be from a playlist
                  poster="hqdefault" // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
                  title="Farmeqplus youtube" // a11y, always provide a title for iFrames: https://dequeuniversity.com/tips/provide-iframe-titles Help the web be accessible ;)
                  wrapperClass="yt-lite"
                />
              </Grid>
              {
                rawVideos.length > 1 &&
                <Grid item xs={12} md={3} style={{ order: matchesDesktop ? -1 : 1}}>
                  <VideoPicker
                    videos={videos} 
                    currentVideo={currentVideo} 
                    setCurrentVideo={setCurrentVideo} 
                    direction={matchesDesktop ? "vertical" : 'horizontal'}
                  />
                </Grid>
              }
            </Grid>
            <div className={classes.buttonWrapper}>
              <Button
                component="a"
                href="https://www.youtube.com/channel/UCM390aYFA8PZ2pcKNtXwLfQ/videos"
                target="_blank"
                rel="noopener norefferrer"
                variant="contained"
                className={classes.button}>
                  more video
                </Button>
            </div>
        </div>
      </Container>
    </Root>
  );
}

export default VideoSection;